import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import ap from '../../assets/andalusia-labs.svg';

const Container = styled(Box)`
  margin-top: 4rem;

  @media only screen and (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const Heading = styled.img`
  width: 38rem;
`;

const SubHeading = styled.p`
  font-size: var(--h1-font-size);
  font-weight: 500;
  text-align: center;
  color: #9A9A9A;
  letter-spacing: -0.1rem;
`;

const Info = () => {
  return (
    <Container className="section-container" zIndex="999">
      <Heading src={ap} alt="ap" />
      <SubHeading>Building foundational digital infrastructure, optimized for internet markets.</SubHeading>
    </Container>
  );
}

export default Info;
