import React from 'react';
import karakLogo from '../../assets/karak-logo.png';
import styled from 'styled-components';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 6rem;
  padding-top: 1rem;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 4rem;
  column-gap: 8rem;
  border-radius: 10px;
  border: 0.25px solid #80808080;
  background: linear-gradient(111deg, rgba(255, 255, 255, 0.11) -8.95%, rgba(255, 255, 255, 0.16) 114%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
  opacity: 85%;

  @media only screen and (max-width: 998px) {
    flex-direction: column;
    column-gap: 0rem;
    row-gap: 4rem;
    padding: 6rem 3rem;
  }
`;

const LogoFrame = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: 30rem;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 32%;

  @media only screen and (max-width: 1211px) {
    width: 40%;
  }

  @media only screen and (max-width: 998px) {
    align-items: center;
    width: 100%;
    margin: auto;
  }
`;

const Text = styled.p`
  font-size: 0.9rem;
  color: #EDEDED;
  font-weight: 400;
  line-height: 1.25rem;

  @media only screen and (max-width: 998px) {
    text-align: center;
  }
`;

const SubText = styled.p`
  font-size: 0.8rem;
  color: #EDEDED;
  font-weight: 400;
`;

const LearnMore = styled.div`
  display: flex;
  padding: 0.4rem 0.5rem;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  border-radius: 3.12px;
  border: 1px solid rgba(154, 154, 154, 0.80);

  &:hover {
    color: white;
    cursor: pointer;
    opacity: 65%;
  }
`;

const WhatWereBuilding = () => {
  return (
    <FlexBox id="whatwerebuilding">
      <Card>
        <LogoFrame>
          <Logo src={karakLogo} alt="karak-logo" />
        </LogoFrame>
        <InfoContainer>
          <Text>The global base layer for nation states, enabling them to create secure, specialized blockchain networks, optimized for internet markets.</Text>
          <LearnMore
            onClick={() => {
              window.open('https://karak.network');
            }}
          >
            <SubText>Learn more</SubText>
          </LearnMore>
        </InfoContainer>
      </Card>
    </FlexBox>
  );
}

export default WhatWereBuilding;
