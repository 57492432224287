import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: start;
  column-gap: 6rem;
  margin-bottom: 5rem;

  @media only screen and (max-width: 790px) {
    grid-template-columns: auto auto auto auto;
    column-gap: 2.5rem;
    row-gap: var(--row-gap);
    width: 100%;
  }

  @media only screen and (max-width: 499px) {
    row-gap: var(--row-gap);
    grid-template-columns: auto auto;
    width: 100%;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.2rem;
`;

const Title = styled.p`
  color: #d7d7d7;
  font-size: 0.85rem;
  font-weight: bold;
  // margin-bottom: 0.5rem;
`;

const TextLink = styled(Link)`
  color: #bdbdbd !important;
  font-size: 0.75rem !important;

  &:focus {
    outline: none;
  }
`;

const GridLink = () => {
  return (
    <GridBox>
      {/* <FlexBox>
        <Title>Community</Title>
      </FlexBox> */}
      <FlexBox>
        <Title>Company</Title>
        <TextLink href="https://twitter.com/AndalusiaLabs" _focus={{ outline: 'none' }} isExternal>X (Twitter)</TextLink>
        <TextLink href="mailto:info@andalusia-labs.com" _focus={{ outline: 'none' }} isExternal>Contact</TextLink>
        <TextLink href="/privacy" _focus={{ outline: 'none' }}>Privacy</TextLink>
        <TextLink href="/terms" _focus={{ outline: 'none' }}>Terms</TextLink>
      </FlexBox>
    </GridBox>
  );
}

export default GridLink;
